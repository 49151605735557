import Head from 'next/head';
import { openGraphImage } from './media/utils/imgix';

/**
 * This a wrapper for Head tag from next that allows you to add elements to the
 * head section of the site. This makes it easy to add SEO Elements easily.
 *
 */
export default function SeoMetaTags({
  title,
  description,
  imageKey,
  showWebsiteName = true,
  keywords = [],
  cdn,
}: SeoMetaTagsProps): JSX.Element {
  const fullTitle = showWebsiteName ? `${title} - Skyhook` : title;
  return (
    <>
      <Head>
        <title>{fullTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords?.join(', ')} />

        {/* Facebook Tags */}
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description} />
        {imageKey && (
          <meta
            property="og:image"
            content={openGraphImage(imageKey, {}, cdn)}
          />
        )}

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SkyhookAdv" />
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={description} />
        {imageKey && (
          <meta
            name="twitter:image"
            content={openGraphImage(imageKey, {}, cdn)}
          />
        )}
      </Head>
    </>
  );
}

export interface SeoMetaTagsProps {
  title: string;
  description: string;
  imageKey?: string;
  showWebsiteName?: boolean;
  cdn?: string;
  keywords?: string[];
}
