import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomepageLandingPages } from '@website/pages';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import LandingPageCard from '../LandingPages/LandingPageCard';

type Props = {
  popularCountriesSections: HomepageLandingPages['items'][number];
};

export default function PopularCountries({ popularCountriesSections }: Props) {
  if (!popularCountriesSections) return null;

  return (
    <section className="home-py-5">
      <Container>
        <Row className="mb-3">
          <Col md="8">
            <p className="subtitle text-primary">Explore By Destination</p>
            <h2 className="mb-0">Popular countries</h2>
          </Col>
          <Col
            md="4"
            className="d-lg-flex align-items-center justify-content-end"
          >
            <Link href="/trips" className="text-muted text-sm">
              See all trips
              <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
            </Link>
          </Col>
        </Row>
        <Row className="">
          {popularCountriesSections.landingPagesCollection.items.map(
            (country) => (
              <Col
                key={country.slug}
                xs="6"
                md="2"
                className="mb-4"
                data-aos="fade-up"
              >
                <LandingPageCard
                  image={country.image.url}
                  text={country.title}
                  slug={country.slug}
                />
              </Col>
            ),
          )}
        </Row>
      </Container>
    </section>
  );
}
