import { useRef } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDraggable } from 'react-use-draggable-scroll';
import { TripAlgoliaHit } from '../trips/search/TripSearchResult';
import CardRoom from './CardRoom';

export default function SwiperComponent({
  className,
  data,
}: {
  className?: string;
  data: Array<TripAlgoliaHit>;
}) {
  const ref = useRef();
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
  });

  // Loader
  if (!data) return <Spinner animation="border" />;

  return (
    <Row className={className}>
      <div
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          scrollSnapType: 'x',
          overscrollBehaviorX: 'contain',
        }}
        className="d-flex scrollbar-hide py-3"
        {...events}
        ref={ref}
      >
        {data.map((slide) => (
          <Col
            xs={8}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            className="hover-animate me-3 me-lg-4"
            key={slide.trip.urlSlug}
          >
            <CardRoom
              data={slide}
              xs={8}
              sm={5}
              md={4}
              lg={3}
              xl={2}
              aspectRatio="1.2:1"
            />
          </Col>
        ))}
      </div>
    </Row>
  );
}
