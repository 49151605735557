/* eslint-disable no-param-reassign */
import CardRoom from '@website/components/directoryTheme/CardRoom';
import { FetchGroupAndTripForIndexingQuery } from '@website/types/graphqlOperations';
import { TripSearchIndex } from '@website/types/TripSearchIndex';
import { Col } from 'react-bootstrap';

export type TripAlgoliaHit = Pick<
  TripSearchIndex,
  | 'country'
  | 'guestPrice'
  | 'id'
  | 'start'
  | 'remainingSpaces'
  | 'guestPriceBeforeDiscount'
> & {
  trip: Pick<
    FetchGroupAndTripForIndexingQuery['group']['trip'],
    | 'active'
    | 'urlSlug'
    | 'reviewsSummary'
    | 'title'
    | 'image'
    | 'overlayText'
    | 'minimumPrice'
    | 'id'
  > & { host: { name: string } };
};

interface Props {
  hit: TripAlgoliaHit;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  type?: 'trip' | 'group';
}

/**
 * Trip Search Result
 */
export default function TripSearchResult({
  hit,
  xs = 12,
  sm = 6,
  md = 6,
  lg = 4,
  xl = 3,
  type = 'trip',
}: Props) {
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      className="px-2 py-1"
      data-cy="trip-card-col"
    >
      <div className="w-100 h-100 hover-animate">
        <CardRoom
          data={hit}
          xs={12}
          sm={6}
          md={6}
          lg={4}
          xl={3}
          fluid
          type={type}
        />
      </div>
    </Col>
  );
}
