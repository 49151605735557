import HomeBlogPosts, {
  BlogPost,
  homeBlogPosts,
} from '@website/components/home/HomeBlogPosts';
import HomepageHeaderHero from '@website/components/home/HomepageHeaderHero';
import NewAdventures from '@website/components/home/NewAdventures';
import PopularCountiesDestinations from '@website/components/home/PopularCountries';
import PopularExperiences from '@website/components/home/PopularExperiences';
import PopularTrips from '@website/components/home/PopularTrips';
import SeoMetaTags from '@website/components/seoMetaTags';
import { AlgoliaSearchClient } from '@website/components/trips/search/AlgoliaSearchClient';
import { TripAlgoliaHit } from '@website/components/trips/search/TripSearchResult';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { GetStaticPropsResult } from 'next';
import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';
import adventuresImage from '../../public/content/img/photo/photo-1497436072909-60f360e1d4b1.jpg';
import signUpImage from '../../public/images/hosts/signup-bg.jpg';
import { contentFulClient } from './blog/[postSlug]';

const SalesPopupProvider = dynamic(
  () =>
    import('@website/components/SalesPopupProvider').then(
      (mod) => mod.SalesPopupProvider,
    ),
  { ssr: false },
);

export type HomepageLandingPages = {
  items: {
    sectionName: string;
    slug: string;
    landingPagesCollection: {
      items: {
        title: string;
        slug: string;
        image: {
          url: string;
        };
      }[];
    };
  }[];
};

const landingPagesSections = gql`
  query {
    homeLandingPagesListsCollection(limit: 2) {
      items {
        sectionName
        slug
        landingPagesCollection {
          items {
            image {
              url
            }
            slug
            title
          }
        }
      }
    }
  }
`;

type Props = {
  popularTrips: TripAlgoliaHit[];
  newAdventures: TripAlgoliaHit[];
  sections: HomepageLandingPages['items'];
  posts: BlogPost[];
};

export async function getStaticProps(): Promise<GetStaticPropsResult<Props>> {
  // fetch landing pages list
  const res = await contentFulClient.query({
    query: landingPagesSections,
    fetchPolicy: 'no-cache',
  });

  const sections = res.data.homeLandingPagesListsCollection
    .items as HomepageLandingPages['items'];

  const resBlogPosts = await contentFulClient.query({
    query: homeBlogPosts,
  });

  const posts = resBlogPosts.data.blogPostCollection.items as BlogPost[];

  const startOfCurrentHour = DateTime.utc().startOf('hour').toMillis() / 1000;
  const popularResults = await AlgoliaSearchClient.search<TripAlgoliaHit>([
    {
      indexName: 'trips',
      params: {
        filters: `trip.active:"ACTIVE" AND  private:false AND startTimestamp>${startOfCurrentHour}`,
        hitsPerPage: 12,
      },
    },
  ]);

  const newResults = await AlgoliaSearchClient.search<TripAlgoliaHit>([
    {
      indexName: 'trips',
      params: {
        filters: `trip.active:"ACTIVE" AND trip.isRecentTrip:true AND private:false AND startTimestamp>${startOfCurrentHour}`,
        hitsPerPage: 12,
      },
    },
  ]);

  return {
    props: {
      popularTrips: popularResults.results[0].hits,
      newAdventures: newResults.results[0].hits,
      sections,
      posts,
    },
    revalidate: 60,
  };
}

export default function Home({
  popularTrips,
  sections,
  posts,
  newAdventures,
}: Props) {
  return (
    <>
      <style global jsx>{`
        .home-py-5 {
          padding-top: 3rem !important;
          padding-bottom: 3rem !important;
        }

        /* on mobile, reduce padding */
        @media (max-width: 767px) {
          .home-py-5 {
            padding-top: 1.5rem !important;
            padding-bottom: 1.5rem !important;
          }
        }
      `}</style>
      <SeoMetaTags
        title="Skyhook - Book local adventure guides"
        description="Book adventure holidays with authentic local guides, around the world. Everest Base Camp, Morocco Highlights, Tour du Mont Blanc and more!"
        imageKey={popularTrips[0]?.trip.image.file?.key}
        showWebsiteName={false}
      />
      <div className="Home" style={{ marginBottom: -24 }}>
        <HomepageHeaderHero />

        <section className="home-py-5 bg-gray-100">
          <Container>
            <div className="text-center pb-lg-4">
              <p className="subtitle text-secondary">Incredible experiences</p>
              <h2 className="mb-5">
                Join a small group adventure at local prices.
              </h2>
            </div>
            <Row>
              <Col lg={3} className="mb-3 mb-lg-0 text-center">
                <div className="px-0 px-lg-3">
                  <h3 className="h5">Best local guides</h3>
                  <p className="text-muted">
                    All trips are run by qualified & experienced local guides
                    and companies, handpicked, and vetted by us.
                  </p>
                </div>
              </Col>
              <Col lg={3} className="mb-3 mb-lg-0 text-center">
                <div className="px-0 px-lg-3">
                  <h3 className="h5">Best prices</h3>
                  <p className="text-muted">
                    Secure and financially protected bookings at direct prices,
                    ensuring you never pay a markup.
                  </p>
                </div>
              </Col>
              <Col lg={3} className="mb-3 mb-lg-0 text-center">
                <div className="px-0 px-lg-3">
                  <h3 className="h5">Great social vibes</h3>
                  <p className="text-muted">
                    Small group tours provide a richer experience. Travelling
                    alone? Over 50% of Skyhook travellers are solos.
                  </p>
                </div>
              </Col>

              <Col lg={3} className="mb-3 mb-lg-0 text-center">
                <div className="px-0 px-lg-3">
                  <h3 className="h5">Exclusive benefits</h3>
                  <p className="text-muted">
                    Enjoy discounts from our gear and travel partners and access
                    our flexible cancellation policy with lifetime deposits.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <PopularTrips trips={popularTrips} />

        <PopularCountiesDestinations
          popularCountriesSections={sections.find(
            (item) => item.slug === 'popular-countries',
          )}
        />

        <section className="home-py-5 bg-gray-100">
          <Container>
            <Row className="justify-content-center">
              <Col
                xs="12"
                lg="auto"
                className="d-flex justify-content-center mb-4"
              >
                <Image
                  src={signUpImage}
                  className="rounded-pill"
                  width={300}
                  height={200}
                />
              </Col>
              <Col xs={12} lg={6} className="text-center text-md-left">
                <h4 className="mb-4">
                  We are more than a mere booking platform
                </h4>
                <p className="mb-4">
                  At Skyhook we're bound by our shared passion for exploration.
                  By joining our community, you gain exclusive access to
                  top-tier local guides, connections with fellow intrepid
                  travellers, special discounts from premier gear outfitters and
                  travel partners, and a secure online booking experience.
                </p>
                <p className="mb-4">Discover Skyhook. Life's an adventure!</p>
                <Link href="/about" passHref legacyBehavior>
                  <Button variant="outline-primary">Find out more</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>

        <PopularExperiences
          popularExperiencesSection={sections.find(
            (item) => item.slug === 'popular-experiences',
          )}
        />

        <NewAdventures trips={newAdventures} />

        <section className="py-7 position-relative dark-overlay">
          <Image
            src={adventuresImage}
            alt="Find your next adventure with Skyhook"
            className="bg-image"
            layout="fill"
          />
          <Container>
            <div className="overlay-content text-white py-lg-3">
              <h3 className="display-4 fw-bold text-shadow mb-5">
                Live your epic life, one unique adventure at a time.
              </h3>

              <Link href="/trips" passHref legacyBehavior>
                <Button variant="light">Find your next adventure</Button>
              </Link>
            </div>
          </Container>
        </section>

        <HomeBlogPosts posts={posts} />
      </div>
      <SalesPopupProvider bottomPosition={10} />
    </>
  );
}
