import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gql from 'graphql-tag';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import CardPost from '../directoryTheme/CardPost';

export type BlogPost = {
  sys: {
    firstPublishedAt: Date;
  };
  urlSlug: string;
  title: string;
  headerImage: {
    url: string;
  };
};

export const homeBlogPosts = gql`
  query {
    blogPostCollection(order: sys_firstPublishedAt_DESC, limit: 5) {
      items {
        sys {
          firstPublishedAt
        }
        urlSlug
        title
        headerImage {
          url
        }
      }
    }
  }
`;

type Props = {
  posts: BlogPost[];
};

export default function HomeBlogPosts({ posts }: Props) {
  if (!posts) return null;

  return (
    <section className="home-py-5">
      <Container>
        <Row className="mb-5">
          <Col md="8">
            <p className="subtitle text-primary">GET INSPIRED</p>
            <h2 className="mb-0">Latest travel guides</h2>
          </Col>
          <Col
            md="4"
            className="d-lg-flex align-items-center justify-content-end"
          >
            <Link href="/blog" className="text-muted text-sm">
              See all guides
              <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
            </Link>
          </Col>
        </Row>
        <Row className="mb-5">
          {posts.map((post, index) => {
            // The first post is featured so we skip it
            if (index === 0) return null;

            return (
              <Col
                key={post.urlSlug}
                sm="6"
                lg="3"
                className="mb-4 hover-animate"
              >
                <CardPost post={post} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}
