import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomepageLandingPages } from '@website/pages';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import LandingPageCard from '../LandingPages/LandingPageCard';

type Props = {
  popularExperiencesSection: HomepageLandingPages['items'][number];
  hideMobileSection?: boolean;
};

export default function PopularExperiences({
  popularExperiencesSection,
  hideMobileSection = false,
}: Props) {
  if (!popularExperiencesSection) return null;

  const keyExperience =
    popularExperiencesSection.landingPagesCollection.items[0];

  // we want the second and third experience
  const secondExperienceGroup =
    popularExperiencesSection.landingPagesCollection.items.slice(1, 4);

  // we want the fifth experience
  const fifthAndSixthExperience =
    popularExperiencesSection.landingPagesCollection.items.slice(4, 6);

  // and then the rest
  const restOfExperiences =
    popularExperiencesSection.landingPagesCollection.items.slice(6);

  return (
    <>
      <section className="home-py-5">
        <Container>
          <Row className="mb-3">
            <Col md="8">
              <p className="subtitle text-primary">CHOOSE YOUR TRAVEL STYLE</p>
              <h2 className="mb-0">Popular experiences</h2>
            </Col>
            <Col
              md="4"
              className="d-lg-flex align-items-center justify-content-end"
            >
              <Link href="/trips" className="text-muted text-sm">
                See all trips
                <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
              </Link>
            </Col>
          </Row>
          {/* for large screen */}
          <Row className="d-none d-md-flex">
            <Col className="p-0 px-2 " md="4" sm="12">
              <LandingPageCard
                image={keyExperience.image.url}
                text={keyExperience.title}
                slug={keyExperience.slug}
                className="w-full h-100 rounded"
              />
            </Col>
            <Col className="p-0 px-2" md="8" sm="12">
              <Row>
                {secondExperienceGroup.map((country) => (
                  <Col className="px-2" key={country.slug} md="4" xs="12">
                    <LandingPageCard
                      image={country.image.url}
                      text={country.title}
                      slug={country.slug}
                    />
                  </Col>
                ))}

                <Col className="p-0 px-2 pt-3" md="12">
                  <Row>
                    {fifthAndSixthExperience.map((country) => (
                      <Col className="px-3" key={country.slug} md="6" xs="12">
                        <LandingPageCard
                          key={country.slug}
                          image={country.image.url}
                          text={country.title}
                          slug={country.slug}
                          ratio={'2x1'}
                          style={{
                            // we need to use this because of the way the ratio class
                            // is implemented in bootstrap
                            // @ts-expect-error
                            '--bs-aspect-ratio': '50%',
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="py-2 d-none d-md-flex">
            {restOfExperiences.map((country) => (
              <Col className="px-2" key={country.slug} xs="6" md="3">
                <LandingPageCard
                  image={country.image.url}
                  text={country.title}
                  slug={country.slug}
                />
              </Col>
            ))}
          </Row>

          {!hideMobileSection && (
            <Row className="d-flex d-md-none">
              {popularExperiencesSection.landingPagesCollection.items.map(
                (country) => (
                  <Col className="p-2" key={country.slug} xs="6" md="3">
                    <LandingPageCard
                      image={country.image.url}
                      text={country.title}
                      slug={country.slug}
                    />
                  </Col>
                ),
              )}
            </Row>
          )}
        </Container>
      </section>
    </>
  );
}
