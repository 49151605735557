import Link from 'next/link';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { TripAlgoliaHit } from '../trips/search/TripSearchResult';
import Swiper from '../directoryTheme/Swiper';

type Props = {
  trips: TripAlgoliaHit[];
};

export default function PopularTrips({ trips }: Props) {
  if (!trips[0]) return null;

  return (
    <section className="home-py-5">
      <Container>
        <Row>
          <Col md="8">
            <p className="subtitle text-primary">Find your next adventure</p>
            <h2 className="mb-0">Popular trips</h2>
          </Col>
          <Col
            md="4"
            className="d-lg-flex align-items-center justify-content-end"
          >
            <Link href="/trips" className="text-muted text-sm">
              See all trips
              <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
            </Link>
          </Col>
        </Row>
        <Swiper className="guides-slider mx-n2 pt-3" data={trips} />
      </Container>
    </section>
  );
}
