import Image from 'next/legacy/image';
import Link from 'next/link';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaHeart, FaSearch } from 'react-icons/fa';
import headerImage from '../../../public/images/home/hero-background-4.jpg';

export default function HomepageHeaderHero(): JSX.Element {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Image
        src={headerImage}
        layout="fill"
        objectFit="cover"
        sizes="100vw"
        quality={80}
      />

      <style jsx global>{`
        .home-hero {
          width: 100% !important;
          aspect-ratio: 1/1 !important;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          position: relative;
          background: rgba(0, 0, 0, 0.25);
          text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
        }

        @media (min-width: 768px) {
          .home-hero {
            aspect-ratio: 5/3 !important;
          }
        }

        @media (min-width: 992px) {
          .home-hero {
            aspect-ratio: 5/2 !important;
          }
        }

        @media (min-width: 1200px) {
          .home-hero {
            aspect-ratio: 5/1.7 !important;
          }
        }
      `}</style>

      <div className="home-hero">
        <Container className="text-white">
          <Row>
            <Col xl="10" className="mx-auto">
              <div className="text-center text-lg col-12 col-sm-10 col-md-10 col-lg-8 mx-auto">
                <h1
                  className="display-4 fw-bold text-shadow "
                  style={{
                    textShadow: '2px 2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  Group adventures for active people
                </h1>
                <p
                  className="fw-normal h5 text-shadow"
                  style={{
                    textShadow: '2px 2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  Made with <FaHeart className="inline-block text-secondary" />{' '}
                  by local guides
                </p>
                <Link href="/trips" passHref legacyBehavior>
                  <Button variant="light" className="px-3 mt-3">
                    <FaSearch />
                    <span className="ms-2">Find a trip</span>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
