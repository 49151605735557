import Link from 'next/link';
import { Card } from 'react-bootstrap';
import Imgix from 'react-imgix';
import { getImageUrl } from '../media/utils/imgix';

type Props = {
  text: string;
  image: string;
  slug: string;
  className?: string;
  ratio?: '1x1' | '2x1';
  style?: React.CSSProperties;
};

export default function LandingPageCard({
  text,
  image,
  slug,
  className = 'w-full ratio ratio-1x1',
  style,
  ratio = '1x1',
}: Props) {
  return (
    <Link href={`/go/${slug}`} style={style}>
      <Card style={style} className={`${className} rounded shadow`}>
        <Imgix
          src={`${getImageUrl(
            image,
            process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL,
          )}`}
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces',
            fit: 'crop',
            ar: ratio === '2x1' ? '2:1' : '1:1',
          }}
          className="card-img img-fluid rounded ratio w-100 h-100"
          htmlAttributes={{
            alt: text,
          }}
          sizes="(min-width: 1400px) 416px, (min-width: 1200px) 356px, (min-width: 992px) 296px, (min-width: 768px) 223px, (min-width: 576px) 246px, 50vw"
        />
        <Card.ImgOverlay
          style={{
            // @ts-expect-error
            '--bs-bg-opacity': 0.3,
          }}
          className="d-flex align-items-center justify-content-center bg-dark h-100 w-100 rounded"
        >
          <h3 className="card-title leading text-white text-center p-2">
            {text}
          </h3>
        </Card.ImgOverlay>
      </Card>
    </Link>
  );
}
