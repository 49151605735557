import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';
import TripSearchResult, {
  TripAlgoliaHit,
} from '../trips/search/TripSearchResult';

type Props = {
  trips: TripAlgoliaHit[];
};

export default function NewAdventures({ trips }: Props) {
  if (trips.length === 0) return null;

  return (
    <section className="home-py-5">
      <Container>
        <Row>
          <Col md="8">
            <p className="subtitle text-primary">BE A TRAILBLAZER</p>
            <h2 className="mb-0">New adventures</h2>
          </Col>
          <Col
            md="4"
            className="d-lg-flex align-items-center justify-content-end"
          >
            <Link href="/trips" className="text-muted text-sm">
              See all trips
              <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
            </Link>
          </Col>
        </Row>
        <Row className="ps-1 pr-1 mt-4">
          {trips.map((trip) => (
            <TripSearchResult
              key={trip.trip.urlSlug}
              hit={trip}
              xs={6}
              sm={6}
              md={4}
            />
          ))}
        </Row>
      </Container>
    </section>
  );
}
