import Link from 'next/link';

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'react-bootstrap';
import Imgix from 'react-imgix';
import { getImageUrl } from '../media/utils/imgix';

interface BlogPost {
  sys: {
    firstPublishedAt: Date;
  };
  urlSlug: string;
  title: string;
  headerImage: {
    url: string;
  };
}

export default function CardPost({ post }: { post: BlogPost }) {
  return (
    <Card className="border-0 h-100 shadow">
      <Link href={`/blog/${post.urlSlug}`}>
        <Imgix
          src={`${getImageUrl(
            post.headerImage.url,
            process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL,
          )}`}
          imgixParams={{
            auto: 'compress,format,enhance,redeye',
            crop: 'faces',
            fit: 'crop',
            ar: '1080:720',
          }}
          sizes="(min-width: 1400px) 416px, (min-width: 1200px) 356px, (min-width: 992px) 296px, (min-width: 768px) 223px, (min-width: 576px) 246px, 95vw"
          className="img-fluid card-img-top"
        />
      </Link>

      <Card.Body>
        {/* <a
          href="#"
          className="text-uppercase text-muted text-sm letter-spacing-2"
        >
          {post.category}
        </a> */}

        <h5 className="my-2">
          <Link href={`/blog/${post.urlSlug}`} className="text-dark">
            {post.title}
          </Link>
        </h5>

        {/* <p className="my-2 text-muted text-sm">{post.content}</p> */}
        <Link href={`/blog/${post.urlSlug}`} passHref legacyBehavior>
          <Button className="ps-0" variant="link">
            Read more <FontAwesomeIcon icon={faLongArrowAltRight} />
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
}
